<!--
 * @Author: 张阳帅
 * @Date: 2024-09-23 17:52:37
 * @LastEditTime: 2024-10-12 17:53:25
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \szlsnk-user-mp\src\views\gjc\hosp\gjcHospPath.vue
-->
<template>
  <div>
    <!-- <div class="title">
            <div class="title-txt">{{routeData.shortHospName}}</div>
        </div> -->
    <!-- <div class="content">
      <div class="introduction" v-html="routeData" />
    </div>     -->
  </div>
</template>
<script>
import {gjcModel} from '../../../api/gjcApi'

export default {
    data(){
        return{
            routeData:{}
        }
    },
    mounted() {
        // this.getHospitalRouteData()

        // window.location.href = "http://apis.map.qq.com/uri/v1/marker?marker=coord:22.6333,114.0333;addr=深圳蓝生脑科医院"
        window.location.href = 'https://map.qq.com/m/place/info/uid=13920088664538921038&word=深圳蓝生脑科医院&type=point'
    },
    onload() {

    },
    methods:{
        //来院路线
        getHospitalRouteData(){
            const params = {
                yydm: '' //医院代码（医院唯一标识）
            }
            gjcModel.getHospitalRouteData(params).then((result) => {
                console.log('来院路线', result)
                this.routeData = result.data
            })
        }
    }
}
</script>
<style scoped lang="less">
.title{
    width: 100%;
    margin: 32px auto;
    display: flex;
    justify-content: center;
    img{
        width:32px;
        height: 32px;
        position: relative;
        top: 9px;
    }
    .title-txt{
        font-size: 36px;
        font-weight: bold;
        color: #3D3D3D;
        margin: 0 16px;
    }
}
.content{
    .introduction{ 
        margin: 0 24px 20px 24px;
        // width: 100%; 
        // margin-bottom: 3px; 
    }
    .text{
        // text-indent: 60px;
        font-size: 32px;
        color: #3D3D3D;
        line-height: 46px;
    }
}
.page_address{
    padding: 0 32px 32px;
    .text{
        // text-indent: 60px;
        font-size: 28px;
        color: #3D3D3D;
        line-height: 46px;
    }
}
</style>
<style>
    .introduction img{
        width: 100%;
        object-fit: fill;
    }
</style>